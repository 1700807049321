/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { RiArrowRightSLine, RiArrowLeftSLine, RiFileDownloadLine, RiPrinterLine, RiMailLine, RiCheckboxCircleLine, RiTimeLine, RiUserStarLine, RiBarChartFill } from "react-icons/ri"

const PerformanceManagementPage = () => {
  return (
    <Layout>
      <SEO 
        title="Performance Management Policy Template | Review Process Framework"
        description="Download our customizable performance management policy template with guidelines for setting goals, conducting reviews, and providing effective employee feedback."
        keywords={[
          "performance management policy",
          "employee review process",
          "performance evaluation template",
          "performance review guidelines",
          "feedback framework",
          "goal setting policy"
        ]}
      />
      
      {/* Hero Section */}
      <section sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}>
            <h1 sx={{
              fontSize: ['2rem', '2.5rem'],
              fontWeight: 800,
              mb: 3,
              color: 'primary'
            }}>
              Performance Management Policy Template
            </h1>
            
            <p sx={{
              fontSize: ['1.1rem', '1.25rem'],
              maxWidth: '800px',
              mb: 4,
              lineHeight: 1.5,
              color: 'text'
            }}>
              A comprehensive framework for evaluating employee performance, providing feedback,
              and supporting professional development in your organization.
            </p>
            
            <div sx={{
              display: 'flex',
              gap: 3,
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}>
              <Link to="#download" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'primary',
                color: 'white',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  color: 'white'
                }
              }}>
                Download Template <RiFileDownloadLine />
              </Link>
              
              <button type="button" onClick={() => window.print()} sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 3,
                borderRadius: '6px',
                fontSize: '1rem',
                fontWeight: 600,
                border: 'none',
                cursor: 'pointer',
                boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
              }}>
                Print Preview <RiPrinterLine />
              </button>
            </div>
          </div>
        </div>
      </section>
      
      {/* Main Content */}
      <section sx={{
        py: 5,
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1080px',
          mx: 'auto',
          px: 3,
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '280px 1fr'],
          gap: 4
        }}>
          {/* Table of Contents Sidebar */}
          <div sx={{
            bg: 'white',
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            alignSelf: 'start',
            position: ['static', 'static', 'sticky'],
            top: '20px',
            display: ['none', 'none', 'block']
          }}>
            <h3 sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              mb: 3,
              color: 'primary'
            }}>
              Table of Contents
            </h3>
            
            <nav>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                <li sx={{ mb: 2 }}>
                  <Link to="#introduction" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Introduction
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#purpose" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Purpose & Scope
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#review-process" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Review Process
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#goal-setting" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Goal Setting
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#evaluation-criteria" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Evaluation Criteria
                  </Link>
                </li>
                <li sx={{ mb: 2 }}>
                  <Link to="#rating-system" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Rating System
                  </Link>
                </li>
                <li sx={{ mb: 0 }}>
                  <Link to="#templates" sx={{
                    color: 'text',
                    textDecoration: 'none',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    transition: 'color 0.2s ease',
                    '&:hover': {
                      color: 'primary'
                    }
                  }}>
                    Sample Templates
                  </Link>
                </li>
              </ul>
            </nav>
            
            <div sx={{
              mt: 4,
              pt: 4,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)'
            }}>
              <Link to="#download" sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                <RiFileDownloadLine /> Download Template
              </Link>
            </div>
          </div>
          
          {/* Main Content Area */}
          <div>
            {/* Introduction Section */}
            <div id="introduction" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Introduction
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  A well-designed performance management policy is essential for organizational success. 
                  It creates a structured framework for setting expectations, evaluating performance, 
                  providing feedback, and supporting employee development.
                </p>
                
                <p>
                  This template outlines the key components of an effective performance management system. 
                  It can be customized to align with your organization's specific needs, culture, and goals.
                </p>
                
                <div sx={{
                  bg: 'primaryMuted',
                  p: 3,
                  borderRadius: '6px',
                  mt: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary'
                  }}>
                    Benefits of a Structured Performance Management System:
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    }
                  }}>
                    <li>Aligns individual performance with organizational objectives</li>
                    <li>Establishes clear expectations and standards</li>
                    <li>Provides regular feedback to improve performance</li>
                    <li>Identifies training and development needs</li>
                    <li>Recognizes and rewards high performers</li>
                    <li>Creates documentation for personnel decisions</li>
                    <li>Promotes fair and consistent evaluation practices</li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Purpose & Scope Section */}
            <div id="purpose" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Purpose & Scope
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  color: 'text'
                }}>
                  Purpose
                </h3>
                
                <p>
                  This policy establishes a systematic approach to evaluating employee performance, 
                  providing feedback, and supporting professional development at [Company Name]. It aims to:
                </p>
                
                <ul sx={{
                  pl: 4,
                  '& li': {
                    mb: 2
                  }
                }}>
                  <li>Create a fair and transparent performance evaluation process</li>
                  <li>Align individual performance with organizational goals</li>
                  <li>Provide constructive feedback to improve performance</li>
                  <li>Identify and address performance issues promptly</li>
                  <li>Recognize and reward outstanding contributions</li>
                  <li>Support career development and succession planning</li>
                </ul>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Scope
                </h3>
                
                <p>
                  This policy applies to all employees of [Company Name], regardless of position or employment status. 
                  While the frequency and specific methods of performance evaluation may vary by department or position, 
                  the core principles outlined in this policy apply organization-wide.
                </p>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 4
                }}>
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      mb: 2,
                      color: 'primary'
                    }}>
                      <RiCheckboxCircleLine /> Included in Policy
                    </h4>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 2
                      }
                    }}>
                      <li>Performance review procedures</li>
                      <li>Goal-setting processes</li>
                      <li>Performance metrics and standards</li>
                      <li>Feedback mechanisms</li>
                      <li>Performance improvement plans</li>
                      <li>Documentation requirements</li>
                    </ul>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      mb: 2,
                      color: 'primary'
                    }}>
                      <RiTimeLine /> Review Frequency
                    </h4>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 2
                      }
                    }}>
                      <li>Annual comprehensive reviews</li>
                      <li>Quarterly progress check-ins</li>
                      <li>New hire 30/60/90 day reviews</li>
                      <li>Role change or promotion reviews</li>
                      <li>Project completion assessments</li>
                      <li>Continuous feedback mechanisms</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Review Process Section */}
            <div id="review-process" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Review Process
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name]'s performance review process follows these key steps to ensure a fair,
                  comprehensive, and constructive evaluation:
                </p>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['40px 1fr', '50px 1fr'],
                  gap: 3,
                  mt: 3
                }}>
                  <div sx={{
                    bg: 'primary',
                    color: 'white',
                    height: ['40px', '50px'],
                    width: ['40px', '50px'],
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 700,
                    fontSize: ['1.1rem', '1.25rem'],
                    flexShrink: 0,
                    alignSelf: 'flex-start',
                    mt: 1
                  }}>
                    1
                  </div>
                  <div sx={{ mb: 4 }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 1,
                      color: 'text'
                    }}>
                      Preparation
                    </h4>
                    <p sx={{ mb: 0 }}>
                      Both manager and employee prepare for the review by gathering relevant information, including 
                      performance data, project outcomes, goal achievements, and relevant feedback from peers, clients, 
                      or other stakeholders.
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primary',
                    color: 'white',
                    height: ['40px', '50px'],
                    width: ['40px', '50px'],
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 700,
                    fontSize: ['1.1rem', '1.25rem'],
                    flexShrink: 0,
                    alignSelf: 'flex-start',
                    mt: 1
                  }}>
                    2
                  </div>
                  <div sx={{ mb: 4 }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 1,
                      color: 'text'
                    }}>
                      Self-Assessment
                    </h4>
                    <p sx={{ mb: 0 }}>
                      Employee completes a self-assessment form evaluating their performance against 
                      established goals, job responsibilities, and competencies. This encourages self-reflection 
                      and provides valuable perspective for the discussion.
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primary',
                    color: 'white',
                    height: ['40px', '50px'],
                    width: ['40px', '50px'],
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 700,
                    fontSize: ['1.1rem', '1.25rem'],
                    flexShrink: 0,
                    alignSelf: 'flex-start',
                    mt: 1
                  }}>
                    3
                  </div>
                  <div sx={{ mb: 4 }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 1,
                      color: 'text'
                    }}>
                      Manager Assessment
                    </h4>
                    <p sx={{ mb: 0 }}>
                      Manager completes an assessment of the employee's performance based on established criteria, 
                      goals, and job requirements. This assessment should include specific examples and evidence to 
                      support ratings.
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primary',
                    color: 'white',
                    height: ['40px', '50px'],
                    width: ['40px', '50px'],
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 700,
                    fontSize: ['1.1rem', '1.25rem'],
                    flexShrink: 0,
                    alignSelf: 'flex-start',
                    mt: 1
                  }}>
                    4
                  </div>
                  <div sx={{ mb: 4 }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 1,
                      color: 'text'
                    }}>
                      Review Meeting
                    </h4>
                    <p sx={{ mb: 0 }}>
                      Manager and employee meet to discuss the assessment, share feedback, and have an open 
                      dialogue about performance. This discussion should be two-way, with the employee having 
                      the opportunity to provide input and perspective.
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primary',
                    color: 'white',
                    height: ['40px', '50px'],
                    width: ['40px', '50px'],
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 700,
                    fontSize: ['1.1rem', '1.25rem'],
                    flexShrink: 0,
                    alignSelf: 'flex-start',
                    mt: 1
                  }}>
                    5
                  </div>
                  <div sx={{ mb: 4 }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 1,
                      color: 'text'
                    }}>
                      Goal Setting
                    </h4>
                    <p sx={{ mb: 0 }}>
                      Together, manager and employee establish new performance goals and development objectives 
                      for the upcoming period. Goals should be specific, measurable, achievable, relevant, and 
                      time-bound (SMART).
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primary',
                    color: 'white',
                    height: ['40px', '50px'],
                    width: ['40px', '50px'],
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 700,
                    fontSize: ['1.1rem', '1.25rem'],
                    flexShrink: 0,
                    alignSelf: 'flex-start',
                    mt: 1
                  }}>
                    6
                  </div>
                  <div sx={{ mb: 0 }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 1,
                      color: 'text'
                    }}>
                      Documentation & Follow-Up
                    </h4>
                    <p sx={{ mb: 0 }}>
                      The review discussion, performance assessment, and new goals are documented and signed by 
                      both parties. Regular follow-up meetings are scheduled to track progress and provide 
                      ongoing feedback.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Goal Setting Section */}
            <div id="goal-setting" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  sx={{ color: 'primary' }}
                  aria-hidden="true"
                >
                  <title>Goal Setting icon</title>
                  <circle cx="12" cy="12" r="10"></circle>
                  <circle cx="12" cy="12" r="6"></circle>
                  <circle cx="12" cy="12" r="2"></circle>
                </svg>
                Goal Setting
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  Effective goal setting is a cornerstone of performance management. [Company Name] uses the SMART framework
                  to establish meaningful and measurable goals that align with organizational objectives while supporting 
                  individual development.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 3,
                  color: 'text'
                }}>
                  SMART Goal Framework
                </h3>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  my: 3
                }}>
                  <ul sx={{
                    pl: 0,
                    listStyle: 'none',
                    mb: 0
                  }}>
                    <li sx={{
                      mb: 3,
                      display: 'flex',
                      flexDirection: ['column', 'row'],
                      gap: [2, 3]
                    }}>
                      <div sx={{
                        bg: 'primary',
                        color: 'white',
                        fontWeight: 700,
                        p: 2,
                        borderRadius: '6px',
                        minWidth: ['auto', '120px'],
                        textAlign: 'center'
                      }}>
                        Specific
                      </div>
                      <div>
                        Goals should be clear, detailed, and unambiguous. They should answer the questions: 
                        What needs to be accomplished? Who is involved? Why is it important?
                      </div>
                    </li>
                    
                    <li sx={{
                      mb: 3,
                      display: 'flex',
                      flexDirection: ['column', 'row'],
                      gap: [2, 3]
                    }}>
                      <div sx={{
                        bg: 'primary',
                        color: 'white',
                        fontWeight: 700,
                        p: 2,
                        borderRadius: '6px',
                        minWidth: ['auto', '120px'],
                        textAlign: 'center'
                      }}>
                        Measurable
                      </div>
                      <div>
                        Goals should include specific metrics to track progress and determine when the goal has been achieved. 
                        Measurements might include quantities, qualities, costs, or time frames.
                      </div>
                    </li>
                    
                    <li sx={{
                      mb: 3,
                      display: 'flex',
                      flexDirection: ['column', 'row'],
                      gap: [2, 3]
                    }}>
                      <div sx={{
                        bg: 'primary',
                        color: 'white',
                        fontWeight: 700,
                        p: 2,
                        borderRadius: '6px',
                        minWidth: ['auto', '120px'],
                        textAlign: 'center'
                      }}>
                        Achievable
                      </div>
                      <div>
                        Goals should be challenging but attainable. They should stretch abilities but remain 
                        within reach given the available resources, time, and capabilities.
                      </div>
                    </li>
                    
                    <li sx={{
                      mb: 3,
                      display: 'flex',
                      flexDirection: ['column', 'row'],
                      gap: [2, 3]
                    }}>
                      <div sx={{
                        bg: 'primary',
                        color: 'white',
                        fontWeight: 700,
                        p: 2,
                        borderRadius: '6px',
                        minWidth: ['auto', '120px'],
                        textAlign: 'center'
                      }}>
                        Relevant
                      </div>
                      <div>
                        Goals should align with department and organizational objectives and be relevant to the 
                        employee's role and professional development. They should be worthwhile and timely.
                      </div>
                    </li>
                    
                    <li sx={{
                      mb: 0,
                      display: 'flex',
                      flexDirection: ['column', 'row'],
                      gap: [2, 3]
                    }}>
                      <div sx={{
                        bg: 'primary',
                        color: 'white',
                        fontWeight: 700,
                        p: 2,
                        borderRadius: '6px',
                        minWidth: ['auto', '120px'],
                        textAlign: 'center'
                      }}>
                        Time-bound
                      </div>
                      <div>
                        Goals should have defined start and end dates. Timeframes create a sense of urgency and help 
                        prioritize efforts. They also provide natural check-in points for progress reviews.
                      </div>
                    </li>
                  </ul>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Types of Goals
                </h3>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 3
                }}>
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Performance Goals
                    </h4>
                    
                    <p>
                      Focus on specific job responsibilities and expected outcomes. These goals relate directly 
                      to the employee's current role and primary duties.
                    </p>
                    
                    <p sx={{ fontStyle: 'italic', mb: 0 }}>
                      Example: "Increase customer satisfaction rating from 85% to 90% by implementing three service 
                      improvements this quarter."
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Development Goals
                    </h4>
                    
                    <p>
                      Focus on skill enhancement, knowledge acquisition, or career advancement. These goals support 
                      long-term professional growth and may prepare employees for future roles.
                    </p>
                    
                    <p sx={{ fontStyle: 'italic', mb: 0 }}>
                      Example: "Complete advanced project management certification by year-end to enhance leadership capabilities."
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Behavioral Goals
                    </h4>
                    
                    <p>
                      Focus on workplace behaviors, soft skills, and interpersonal capabilities. These goals address how 
                      employees approach their work and interact with others.
                    </p>
                    
                    <p sx={{ fontStyle: 'italic', mb: 0 }}>
                      Example: "Improve team collaboration by leading two cross-functional projects and soliciting feedback 
                      from all stakeholders."
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Strategic Goals
                    </h4>
                    
                    <p>
                      Focus on contributions to broader organizational objectives. These goals connect individual efforts 
                      to the company's mission, vision, and strategic priorities.
                    </p>
                    
                    <p sx={{ fontStyle: 'italic', mb: 0 }}>
                      Example: "Support the company's sustainability initiative by reducing department paper usage by 30% 
                      and implementing three digital alternatives."
                    </p>
                  </div>
                </div>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  mt: 4
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="20" 
                      height="20" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      sx={{ color: 'primary' }}
                      aria-hidden="true"
                    >
                      <title>Best Practices icon</title>
                      <path d="M12 2L2 7l10 5 10-5-10-5z"></path>
                      <path d="M2 17l10 5 10-5"></path>
                      <path d="M2 12l10 5 10-5"></path>
                    </svg>
                    Goal Setting Best Practices
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    },
                    '& li:last-child': {
                      mb: 0
                    }
                  }}>
                    <li>Limit the number of goals to 3-5 per review period to maintain focus</li>
                    <li>Include both individual and team goals where appropriate</li>
                    <li>Align with departmental and organizational strategic priorities</li>
                    <li>Establish clear metrics and milestones for tracking progress</li>
                    <li>Consider both short-term outcomes and long-term development</li>
                    <li>Review and adjust goals as needed during check-in meetings</li>
                    <li>Ensure employee input and buy-in during the goal-setting process</li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Evaluation Criteria Section */}
            <div id="evaluation-criteria" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiBarChartFill sx={{ color: 'primary' }} /> Evaluation Criteria
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] evaluates employee performance using a comprehensive set of criteria that address both 
                  what is accomplished (results) and how it is accomplished (behaviors). This balanced approach ensures a 
                  thorough and fair assessment of overall performance.
                </p>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 3,
                  color: 'text'
                }}>
                  Core Performance Areas
                </h3>
                
                <div sx={{
                  mt: 3,
                  display: 'grid',
                  gap: 3
                }}>
                  <div sx={{
                    bg: '#f8f9fa',
                    border: '1px solid #e9ecef',
                    borderRadius: '6px',
                    p: 3
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Job Knowledge & Technical Skills
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      Assesses understanding of job responsibilities and proficiency in the technical skills required 
                      for the position. Evaluates the employee's knowledge of systems, processes, procedures, and 
                      technical tools relevant to their role.
                    </p>
                    
                    <div sx={{
                      mt: 3,
                      pt: 3,
                      borderTop: '1px dashed #e9ecef'
                    }}>
                      <h5 sx={{
                        fontSize: '0.95rem',
                        fontWeight: 600,
                        mb: 2,
                        color: 'text'
                      }}>
                        Sample Criteria:
                      </h5>
                      
                      <ul sx={{
                        pl: 4,
                        mb: 0,
                        '& li': {
                          mb: 1
                        },
                        '& li:last-child': {
                          mb: 0
                        }
                      }}>
                        <li>Demonstrates expertise in required technical skills</li>
                        <li>Stays current with industry developments and best practices</li>
                        <li>Effectively uses tools and systems required for the role</li>
                        <li>Requires minimal supervision for technical aspects of work</li>
                      </ul>
                    </div>
                  </div>
                  
                  <div sx={{
                    bg: '#f8f9fa',
                    border: '1px solid #e9ecef',
                    borderRadius: '6px',
                    p: 3
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Quality of Work
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      Evaluates the accuracy, thoroughness, and reliability of work products. Considers attention to 
                      detail, adherence to standards, and the overall excellence of deliverables.
                    </p>
                    
                    <div sx={{
                      mt: 3,
                      pt: 3,
                      borderTop: '1px dashed #e9ecef'
                    }}>
                      <h5 sx={{
                        fontSize: '0.95rem',
                        fontWeight: 600,
                        mb: 2,
                        color: 'text'
                      }}>
                        Sample Criteria:
                      </h5>
                      
                      <ul sx={{
                        pl: 4,
                        mb: 0,
                        '& li': {
                          mb: 1
                        },
                        '& li:last-child': {
                          mb: 0
                        }
                      }}>
                        <li>Produces accurate, error-free work</li>
                        <li>Delivers complete, thorough results</li>
                        <li>Adheres to quality standards and procedures</li>
                        <li>Monitors own work and implements quality control measures</li>
                      </ul>
                    </div>
                  </div>
                  
                  <div sx={{
                    bg: '#f8f9fa',
                    border: '1px solid #e9ecef',
                    borderRadius: '6px',
                    p: 3
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Productivity & Efficiency
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      Measures volume of work, timeliness of completion, and efficient use of resources. Evaluates 
                      the employee's ability to manage workload, prioritize effectively, and meet deadlines.
                    </p>
                    
                    <div sx={{
                      mt: 3,
                      pt: 3,
                      borderTop: '1px dashed #e9ecef'
                    }}>
                      <h5 sx={{
                        fontSize: '0.95rem',
                        fontWeight: 600,
                        mb: 2,
                        color: 'text'
                      }}>
                        Sample Criteria:
                      </h5>
                      
                      <ul sx={{
                        pl: 4,
                        mb: 0,
                        '& li': {
                          mb: 1
                        },
                        '& li:last-child': {
                          mb: 0
                        }
                      }}>
                        <li>Consistently meets productivity expectations</li>
                        <li>Completes work within established timeframes</li>
                        <li>Effectively prioritizes tasks based on importance and urgency</li>
                        <li>Uses resources (time, budget, materials) efficiently</li>
                      </ul>
                    </div>
                  </div>
                  
                  <div sx={{
                    bg: '#f8f9fa',
                    border: '1px solid #e9ecef',
                    borderRadius: '6px',
                    p: 3
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Communication Skills
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      Evaluates verbal, written, and interpersonal communication abilities. Considers clarity, 
                      appropriateness, responsiveness, and the ability to adjust communication style based on 
                      the audience and context.
                    </p>
                    
                    <div sx={{
                      mt: 3,
                      pt: 3,
                      borderTop: '1px dashed #e9ecef'
                    }}>
                      <h5 sx={{
                        fontSize: '0.95rem',
                        fontWeight: 600,
                        mb: 2,
                        color: 'text'
                      }}>
                        Sample Criteria:
                      </h5>
                      
                      <ul sx={{
                        pl: 4,
                        mb: 0,
                        '& li': {
                          mb: 1
                        },
                        '& li:last-child': {
                          mb: 0
                        }
                      }}>
                        <li>Expresses ideas clearly and concisely</li>
                        <li>Listens attentively and responds appropriately</li>
                        <li>Communicates effectively with different audiences</li>
                        <li>Provides timely and relevant information to others</li>
                      </ul>
                    </div>
                  </div>
                  
                  <div sx={{
                    bg: '#f8f9fa',
                    border: '1px solid #e9ecef',
                    borderRadius: '6px',
                    p: 3
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Teamwork & Collaboration
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      Assesses the ability to work effectively with others, contribute to team goals, and 
                      build positive working relationships. Evaluates cooperation, support for team members, 
                      and collaborative problem-solving.
                    </p>
                    
                    <div sx={{
                      mt: 3,
                      pt: 3,
                      borderTop: '1px dashed #e9ecef'
                    }}>
                      <h5 sx={{
                        fontSize: '0.95rem',
                        fontWeight: 600,
                        mb: 2,
                        color: 'text'
                      }}>
                        Sample Criteria:
                      </h5>
                      
                      <ul sx={{
                        pl: 4,
                        mb: 0,
                        '& li': {
                          mb: 1
                        },
                        '& li:last-child': {
                          mb: 0
                        }
                      }}>
                        <li>Actively contributes to team projects and initiatives</li>
                        <li>Shares information and resources with colleagues</li>
                        <li>Respects diverse perspectives and work styles</li>
                        <li>Resolves conflicts constructively and professionally</li>
                      </ul>
                    </div>
                  </div>
                  
                  <div sx={{
                    bg: '#f8f9fa',
                    border: '1px solid #e9ecef',
                    borderRadius: '6px',
                    p: 3
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Initiative & Innovation
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      Evaluates self-motivation, proactive problem-solving, and the ability to identify and implement 
                      improvements. Considers creativity, willingness to take on challenges, and contributions to 
                      innovation efforts.
                    </p>
                    
                    <div sx={{
                      mt: 3,
                      pt: 3,
                      borderTop: '1px dashed #e9ecef'
                    }}>
                      <h5 sx={{
                        fontSize: '0.95rem',
                        fontWeight: 600,
                        mb: 2,
                        color: 'text'
                      }}>
                        Sample Criteria:
                      </h5>
                      
                      <ul sx={{
                        pl: 4,
                        mb: 0,
                        '& li': {
                          mb: 1
                        },
                        '& li:last-child': {
                          mb: 0
                        }
                      }}>
                        <li>Takes initiative without being prompted</li>
                        <li>Identifies and solves problems independently</li>
                        <li>Suggests improvements to processes and procedures</li>
                        <li>Embraces new ideas and approaches to work</li>
                      </ul>
                    </div>
                  </div>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Role-Specific Criteria
                </h3>
                
                <p>
                  In addition to the core performance areas, employees are evaluated on criteria specific to their roles, 
                  such as:
                </p>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 3
                }}>
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      For Management Roles
                    </h4>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 1
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Leadership effectiveness</li>
                      <li>Team development and coaching</li>
                      <li>Strategic planning and execution</li>
                      <li>Resource management</li>
                      <li>Change management capabilities</li>
                    </ul>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      For Technical Roles
                    </h4>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 1
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Technical problem-solving</li>
                      <li>System design and architecture</li>
                      <li>Technical documentation</li>
                      <li>Code quality and standards</li>
                      <li>Technical mentorship</li>
                    </ul>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      For Customer-Facing Roles
                    </h4>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 1
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Customer service skills</li>
                      <li>Relationship building</li>
                      <li>Product knowledge</li>
                      <li>Problem resolution</li>
                      <li>Sales effectiveness</li>
                    </ul>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      For Project-Based Roles
                    </h4>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 1
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Project planning and organization</li>
                      <li>Risk management</li>
                      <li>Stakeholder management</li>
                      <li>Budget adherence</li>
                      <li>Timeline management</li>
                    </ul>
                  </div>
                </div>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  mt: 4
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}>
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="20" 
                      height="20" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      sx={{ color: 'primary' }}
                      aria-hidden="true"
                    >
                      <title>Note icon</title>
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                      <polyline points="14 2 14 8 20 8"></polyline>
                      <line x1="16" y1="13" x2="8" y2="13"></line>
                      <line x1="16" y1="17" x2="8" y2="17"></line>
                      <polyline points="10 9 9 9 8 9"></polyline>
                    </svg>
                    Weighting of Criteria
                  </h4>
                  
                  <p sx={{ mb: 0 }}>
                    Performance criteria may be weighted differently based on the employee's role, level, and 
                    department priorities. The specific weighting for each criterion should be established at the 
                    beginning of the review period and communicated clearly to employees. Managers should consult 
                    with Human Resources for guidance on appropriate weighting for different positions.
                  </p>
                </div>
              </div>
            </div>
            
            {/* Rating System Section */}
            <div id="rating-system" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <RiUserStarLine sx={{ color: 'primary' }} /> Rating System
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] uses a standardized rating scale to assess employee performance across multiple dimensions. 
                  Each rating is clearly defined to ensure consistency and fairness in the evaluation process:
                </p>
                
                <div sx={{
                  display: 'grid',
                  gap: 3,
                  mt: 4
                }}>
                  <div sx={{
                    p: 3,
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'rgba(0,0,0,0.1)',
                    bg: 'white'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      5 - Exceptional (Exceeds All Expectations)
                    </h4>
                    <p sx={{ mb: 0 }}>
                      Performance consistently exceeds all job requirements and expectations. Employee demonstrates 
                      exceptional skills, knowledge, and abilities, and makes significant contributions to the team and 
                      organization. Work is of the highest quality and completed ahead of schedule with minimal supervision.
                    </p>
                  </div>
                  
                  <div sx={{
                    p: 3,
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'rgba(0,0,0,0.1)',
                    bg: 'white'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      4 - Exceeds Expectations
                    </h4>
                    <p sx={{ mb: 0 }}>
                      Performance consistently exceeds most job requirements and expectations. Employee demonstrates 
                      strong skills, knowledge, and abilities, and regularly contributes beyond the defined role. 
                      Work is of very high quality and completed on or ahead of schedule with minimal supervision.
                    </p>
                  </div>
                  
                  <div sx={{
                    p: 3,
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'rgba(0,0,0,0.1)',
                    bg: 'white'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      3 - Meets Expectations
                    </h4>
                    <p sx={{ mb: 0 }}>
                      Performance consistently meets all job requirements and expectations. Employee demonstrates 
                      solid skills, knowledge, and abilities, and fulfills all responsibilities of the position. 
                      Work is of good quality and completed on schedule with normal supervision.
                    </p>
                  </div>
                  
                  <div sx={{
                    p: 3,
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'rgba(0,0,0,0.1)',
                    bg: 'white'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      2 - Partially Meets Expectations
                    </h4>
                    <p sx={{ mb: 0 }}>
                      Performance meets some but not all job requirements and expectations. Employee demonstrates 
                      adequate skills, knowledge, and abilities, but may require development in certain areas. 
                      Work quality is inconsistent or requires close supervision to meet standards.
                    </p>
                  </div>
                  
                  <div sx={{
                    p: 3,
                    borderRadius: '8px',
                    border: '1px solid',
                    borderColor: 'rgba(0,0,0,0.1)',
                    bg: 'white'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      1 - Does Not Meet Expectations
                    </h4>
                    <p sx={{ mb: 0 }}>
                      Performance consistently falls below job requirements and expectations. Employee may lack 
                      necessary skills, knowledge, or abilities for the position. Work quality is unsatisfactory 
                      and requires significant improvement. Performance improvement plan is required.
                    </p>
                  </div>
                </div>
                
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  color: 'text'
                }}>
                  Using the Rating System Effectively
                </h3>
                
                <div sx={{
                  bg: '#f8f9fa',
                  border: '1px solid #e9ecef',
                  borderRadius: '6px',
                  p: 3,
                  mt: 3
                }}>
                  <h4 sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 2,
                    color: 'text'
                  }}>
                    Guidelines for Managers
                  </h4>
                  
                  <ul sx={{
                    pl: 4,
                    mb: 0,
                    '& li': {
                      mb: 2
                    },
                    '& li:last-child': {
                      mb: 0
                    }
                  }}>
                    <li><strong>Provide specific examples</strong> to support each rating, focusing on observable behaviors and measurable outcomes</li>
                    <li><strong>Maintain consistency</strong> in how ratings are applied across team members to ensure fairness</li>
                    <li><strong>Avoid recency bias</strong> by considering performance throughout the entire review period</li>
                    <li><strong>Distinguish between</strong> performance issues and factors outside the employee's control</li>
                    <li><strong>Calibrate ratings</strong> with other managers to ensure organizational alignment</li>
                  </ul>
                </div>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 4
                }}>
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Rating Distribution
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      While there is no mandatory distribution of ratings, managers should expect that most employees 
                      will fall in the "Meets Expectations" or "Exceeds Expectations" categories. Ratings at the extremes 
                      (1 or 5) should be less common and require substantial documentation and examples.
                    </p>
                  </div>
                  
                  <div sx={{
                    bg: 'primaryMuted',
                    p: 3,
                    borderRadius: '6px'
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'primary'
                    }}>
                      Overall Rating Calculation
                    </h4>
                    
                    <p sx={{ mb: 0 }}>
                      The overall performance rating is typically calculated by averaging ratings across all applicable 
                      criteria, taking into account any predetermined weighting factors. Human Resources provides guidance 
                      on calculating the final rating based on role-specific considerations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Sample Templates Section */}
            <div id="templates" sx={{
              bg: 'white',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mb: 4
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary',
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="24" 
                  height="24" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="currentColor" 
                  strokeWidth="2" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  sx={{ color: 'primary' }}
                  aria-hidden="true"
                >
                  <title>Templates icon</title>
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="3" y1="9" x2="21" y2="9"></line>
                  <line x1="9" y1="21" x2="9" y2="9"></line>
                </svg>
                Sample Templates
              </h2>
              
              <div sx={{
                fontSize: '1rem',
                color: 'text',
                lineHeight: 1.6
              }}>
                <p>
                  [Company Name] provides standardized templates to support the performance management process. 
                  These templates ensure consistency across the organization while allowing for customization 
                  based on specific departmental or role requirements.
                </p>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mt: 3
                }}>
                  <div sx={{
                    bg: '#f8f9fa',
                    border: '1px solid #e9ecef',
                    borderRadius: '6px',
                    p: 3
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'text'
                    }}>
                      Performance Review Form
                    </h4>
                    
                    <p>
                      Comprehensive form for annual performance evaluations, including sections for:
                    </p>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 1
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Employee and position information</li>
                      <li>Previous period goals and achievements</li>
                      <li>Core competency and skill assessments</li>
                      <li>Overall performance rating</li>
                      <li>Manager and employee comments</li>
                      <li>Signature and acknowledgment fields</li>
                    </ul>
                  </div>
                  
                  <div sx={{
                    bg: '#f8f9fa',
                    border: '1px solid #e9ecef',
                    borderRadius: '6px',
                    p: 3
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'text'
                    }}>
                      Goal Setting Worksheet
                    </h4>
                    
                    <p>
                      Structured template for establishing and documenting SMART goals, including:
                    </p>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 1
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Specific goal description and alignment with company objectives</li>
                      <li>Metrics and measures of success</li>
                      <li>Timeline and milestones</li>
                      <li>Resources and support needed</li>
                      <li>Potential obstacles and mitigation strategies</li>
                      <li>Progress tracking sections</li>
                    </ul>
                  </div>
                  
                  <div sx={{
                    bg: '#f8f9fa',
                    border: '1px solid #e9ecef',
                    borderRadius: '6px',
                    p: 3
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'text'
                    }}>
                      Self-Assessment Form
                    </h4>
                    
                    <p>
                      Template for employees to reflect on and document their performance, including:
                    </p>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 1
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Key accomplishments and achievements</li>
                      <li>Progress against established goals</li>
                      <li>Strengths and areas for development</li>
                      <li>Challenges encountered and solutions implemented</li>
                      <li>Support or resources needed for future success</li>
                      <li>Career aspirations and development interests</li>
                    </ul>
                  </div>
                  
                  <div sx={{
                    bg: '#f8f9fa',
                    border: '1px solid #e9ecef',
                    borderRadius: '6px',
                    p: 3
                  }}>
                    <h4 sx={{
                      fontSize: '1.1rem',
                      fontWeight: 600,
                      mb: 2,
                      color: 'text'
                    }}>
                      Performance Improvement Plan
                    </h4>
                    
                    <p>
                      Structured framework for addressing performance deficiencies, including:
                    </p>
                    
                    <ul sx={{
                      pl: 4,
                      mb: 0,
                      '& li': {
                        mb: 1
                      },
                      '& li:last-child': {
                        mb: 0
                      }
                    }}>
                      <li>Specific performance concerns and expected standards</li>
                      <li>Concrete improvement objectives and success criteria</li>
                      <li>Support, resources, and training to be provided</li>
                      <li>Timeline for improvement and review dates</li>
                      <li>Consequences of not meeting improvement objectives</li>
                      <li>Documentation and acknowledgment sections</li>
                    </ul>
                  </div>
                </div>
                
                <p sx={{ mt: 4 }}>
                  All templates are available in digital format through the company's HRIS platform and in printable 
                  versions from Human Resources. Departments may adapt these templates to address specific needs with 
                  HR approval, provided the core elements remain consistent.
                </p>
              </div>
            </div>
            
            {/* Download Section */}
            <div id="download" sx={{
              bg: 'primaryMuted',
              p: 4,
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
              mt: 5
            }}>
              <h2 sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                mb: 3,
                color: 'primary'
              }}>
                Download Full Performance Management Policy Template
              </h2>
              
              <p sx={{
                fontSize: '1rem',
                color: 'text',
                mb: 3,
                lineHeight: 1.6
              }}>
                Get the complete, customizable template with all sections to implement a comprehensive 
                performance management system in your organization. The full template includes:
              </p>
              
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0,
                mb: 3,
                display: 'grid',
                gridTemplateColumns: ['1fr', '1fr 1fr'],
                gap: 2
              }}>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> MS Word & PDF formats
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Review form templates
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Goal setting worksheets
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Rating scale definitions
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Competency frameworks
                </li>
                <li sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <span sx={{ color: 'primary' }}>✓</span> Implementation guidelines
                </li>
              </ul>
              
              <div sx={{
                display: 'flex',
                gap: 3,
                flexWrap: 'wrap'
              }}>
                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    color: 'white'
                  }
                }}>
                  Download Full Template <RiFileDownloadLine />
                </Link>
                
                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                  }
                }}>
                  Request Customization <RiMailLine />
                </Link>
              </div>
            </div>
            
            {/* Navigation Links */}
            <div sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 4,
              pt: 3,
              borderTop: '1px solid',
              borderColor: 'rgba(0,0,0,0.1)'
            }}>
              <Link to="/policies/employee-handbook" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                <RiArrowLeftSLine /> Previous: Employee Handbook
              </Link>
              
              <Link to="/policies/leave-policy" sx={{
                display: 'inline-flex',
                alignItems: 'center',
                gap: 2,
                color: 'primary',
                textDecoration: 'none',
                fontSize: '0.95rem',
                fontWeight: 600
              }}>
                Next: Leave & Time Off <RiArrowRightSLine />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PerformanceManagementPage 